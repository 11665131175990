
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";
import { roles } from "@/store/stateless/store";
import router from "@/router";

interface CarDetailsBodyParam {
  model_id: string;
  year: string;
}
interface editQuoteBodyParam {
  driver_details_id: any | null;
  customer_id: any;
  car_year: any | null;
  manufacturer_id: any | null;
  manufacturer_name: any | null;
  model_id: any | null;
  model_name: any | null;
  trim_level_id: any | null;
  model_details: any | null;
  is_new: any;
  car_value: any | null;
  first_registration_date: string | null;
  first_registration_date_format: any | null;
  registration_emirate: any | any;
  dob: string | null;
  dob_format: any | null;
  nationality: any | null;
  first_driving_license_country: any | null;
  driving_experience: any | null;
  uae_driving_experience: any | null;
  policy_start_date: string | null;
  policy_start_date_format: any | null;
  claimed_insurance: any;
  no_claim_certificate: any | null;
  claims: string | null;
  gcc_specification: any;
  personal_use: any;
  current_policy_active: any;
  fully_comprehensive: any;
  third_party_liability: any;
  existing_policy_expired: any;
  gender:any|null;
  
  driver_name: string;  
  lead_id: any | null;
}
export interface ICountryMultiselect {
  placeholder: any;
  value: any | null;
  options: any;
  searchable: boolean;
  clearOnSelect: boolean;
}
export default defineComponent({
  name: "Edit-Quote",
  props: {},
  components: {
    LeadDriverDetailsView,
  },
  computed: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    let ShowLoading = ref<boolean>(true);
    let key = ref<any>();
    let editQuoteBodyParam = ref<editQuoteBodyParam>({
      driver_details_id: "",
      customer_id: "",
      car_year: "",
      manufacturer_id: "",
      manufacturer_name: "",
      model_id: "",
      model_name: "",
      trim_level_id: "",
      model_details: "",
      is_new: "",
      car_value: "",
      first_registration_date: "",
      first_registration_date_format: "",
      registration_emirate: 7,
      dob: "",
      dob_format: "",
      nationality: "",
      first_driving_license_country: "",
      driving_experience: "",
      uae_driving_experience: "",
      policy_start_date: "",
      policy_start_date_format: "",
      claimed_insurance: "",
      no_claim_certificate: "",
      claims: "",
      gcc_specification: "",
      personal_use: "",
      current_policy_active: "",
      fully_comprehensive: "",
      third_party_liability: "",
      existing_policy_expired: "",
      gender:null,
      driver_name: "",

      lead_id: "",
    });

    onMounted(() => {
      if(!roles.customerdetails.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Add Lead - Car & Driver Details", ["Leads & Tasks"]);
    });

    let editQuoteObj = computed(() => {
      return store.getters.getEditQuote;
    });
    let editDomStatus = computed(() => {
      return store.getters.getShowEditDriverDetailsStatus;
    });
    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let getDuplicateDataExist = computed(() => {
      return store.getters.getDuplicateQuotesErrorMessage;
    });

    Promise.all([
      QuotesService.fetchQuote({
        driver_details_id: route.params.driverDetailsId,
      }),
    ]).then((data) => {
      
      console.log("afer fetching");
      console.log(editQuoteObj.value);
      
      key.value = editQuoteObj.value.driver_details_id;
    });

    const toLeadsAndTasks = computed(() => {
      return router.currentRoute.value.query.leadsAndTasks
    }) 
    return {
      toLeadsAndTasks,
      // errorMessage,
      // value,
      roles,
      editQuoteObj,
      ShowLoading,
      editDomStatus,
      disableSaveButton,
      getDuplicateDataExist,
      editQuoteBodyParam,
      key,
    };
  },
});
